.mt-60 {
  margin-top: 60px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.ml-150 {
  margin-left: 150px;
}

.p-4-8 {
  padding: 4px 8px;
}

.p-5 {
  padding: 5px;
}

.p-0 {
  padding: 0px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-0 {
  padding-bottom: 0px;
}
