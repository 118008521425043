.block {
  display: block;
}

.flex {
  display: flex;
}

.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}

.w-95 {
  width: 50%;
}

.w-90 {
  width: 50%;
}

.w-85 {
  width: 60%;
}

.w-80 {
  width: 50%;
}

.w-75 {
  width: 60%;
}

.w-70 {
  width: 55%;
}

.w-65 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-55 {
  width: 55%;
}

.w-50 {
  width: 50%;
}

.w-48 {
  width: 48%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-30 {
  width: 30%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-18 {
  width: 18%;
}

.w-17 {
  width: 17%;
}

.w-15 {
  width: 15%;
}

.w-10 {
  width: 10%;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.invoice-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  background-color: $color-gray;
}

.fake-mb-100 {
  margin-bottom: 100px !important;
}

.row {
  position: relative;
  border-bottom: 1px solid $color-gray;
}

.divider {
  border-top: 2px solid $color-gray,
}

.row__remove {
  display: flex;
  padding: 0;
  position: absolute;
  top: 10px;
  right: -20px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  .row:hover &,
  .row:focus-within & {
    opacity: 1;
  }
}
