/* button-reset-invoice */
.button-reset-invoice {
    position: relative;
    top: 195px;
    left: 600px;
    background: url('../images/reset.svg');
    background-color: rgba(255, 0, 0, 0);
    border: none;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    transition: 0.3s;
    opacity: 0.2;
    z-index: 1 !important;
  }
  
  .button-reset-invoice span {
    display: none;
  }
  
  .button-reset-invoice:hover {
    cursor: pointer;
    opacity: 1;
  }

/* button-save-invoice */
.button-save-invoice {
    position: relative;
    top: 195px;
    left: 345px;
    background: url('../images/save.svg');
    background-color: rgba(255, 255, 255, 0);
    border: none;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    transition: 0.3s;
    opacity: 0.2;
    z-index: 2 !important;
  }
  
  .button-save-invoice span {
    display: none;
  }
  
  .button-save-invoice:hover {
    cursor: pointer;
    opacity: 1;
  }

  /* button-load-invoice */
.button-load-invoice {
  position: relative;
  top: 195px;
  left: 365px;
  background: url('../images/load_template.svg');
  background-color: rgba(255, 255, 255, 0);
  border: none;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  transition: 0.3s;
  opacity: 0.2;
  z-index: 2 !important;
}

.button-load-invoice span {
  display: none;
}

.button-load-invoice:hover {
  cursor: pointer;
  opacity: 1;
}

  /* button-download-pdf */
.button-download-pdf {
  position: relative;
  top: 195px;
  left: 450px;
  background: url('../images/download.svg');
  background-color: rgba(255, 0, 0, 0);
  border: none;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  transition: 0.3s;
  opacity: 0.2;
  z-index: 1 !important;
}

.button-download-pdf span {
  display: none;
}

.button-download-pdf:hover {
  cursor: pointer;
  opacity: 1;
}